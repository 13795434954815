/** @jsxImportSource @emotion/react */
import { isUndefined } from 'lodash'
import styled from '@emotion/styled'
import { ExternalIntegrations, Orgs } from 'models'
import { INTEGRATIONS, INTEGRATIONS_DEFAULT_URLS } from 'lib/constants'
import { ExternalIntegration, Organization, trans, TranslationGroup } from 'lib/types'
import { ExternalIntegrationForm } from 'components/partials'
import { ConditionalRender, LoaderOverlay } from 'components/widgets'
import { rowContainerStyles, titleRowContainerStyles, titleTextStyles } from 'styles'
import { MappedInIntegrationForm } from 'components/partials/MappedInIntegrationForm'

const StyledContainer = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '5.0rem',
})

const SettingsContainer = styled.div({
  position: 'relative',
})

const StyledLinkableText = styled.span({
  fontWeight: 'bold',
  fontSize: '1.2rem',
  color: '#29a096',
  texTransform: 'none',
})

const ColumnContainer = styled.div({
  display: 'flex',
  flex: 1,
  flexWrap: 'wrap',
})

const StyledOrgContainer = styled.div({
  button: {
    marginTop: '0px',
  },
})

const ExternalIntegrationForms = ({ forms }: { forms: ExternalIntegration[] }) => {
  return (
    <>
      {forms.map((integration: ExternalIntegration, i) => {
        return <ExternalIntegrationForm model={integration} key={`integration_${i}`} index={i} />
      })}
    </>
  )
}

type Props = {
  org: Organization | null
}

export const ExternalIntegrationsSettings = ({ org }: Props) => {
  const settings: TranslationGroup = trans.settings()
  const selectedOrg = Orgs.useSelectedOrg()
  const integrations = ExternalIntegrations.useSelectedOrgsDevices()
  const loading = ExternalIntegrations.use(({ loading }) => loading)

  const forms: { [key in INTEGRATIONS]: ExternalIntegration } = {
    [INTEGRATIONS.HOTSOS]: {
      name: INTEGRATIONS.HOTSOS,
      props: { isEnabled: false, apiUrl: INTEGRATIONS_DEFAULT_URLS[INTEGRATIONS.HOTSOS] },
    },
    [INTEGRATIONS.KNOWCROSS]: {
      name: INTEGRATIONS.KNOWCROSS,
      props: { isEnabled: false, apiUrl: INTEGRATIONS_DEFAULT_URLS[INTEGRATIONS.KNOWCROSS] },
    },
    [INTEGRATIONS.PINEAPPLE]: {
      name: INTEGRATIONS.PINEAPPLE,
      props: { isEnabled: false, apiUrl: INTEGRATIONS_DEFAULT_URLS[INTEGRATIONS.PINEAPPLE] },
    },
  }

  integrations.forEach(form => {
    const name = form.name
    if (Object.values(INTEGRATIONS).includes(name)) {
      forms[name] = form
    }
  })

  return (
    <SettingsContainer>
      {loading && <LoaderOverlay size={75} />}
      <StyledContainer>
        <div css={titleRowContainerStyles}>
          <h2 css={titleTextStyles}>{settings.external_integration}</h2>
          <StyledOrgContainer>
            <ConditionalRender condition={isUndefined(selectedOrg)}>
              <StyledLinkableText>{settings.select_org_integrations}</StyledLinkableText>
            </ConditionalRender>
          </StyledOrgContainer>
        </div>
      </StyledContainer>
      <ConditionalRender condition={!isUndefined(selectedOrg)}>
        <StyledContainer>
          <div css={rowContainerStyles}>
            <ColumnContainer>
              <ExternalIntegrationForms forms={Object.values(forms)} />
              {/* <MappedInIntegrationForm org={org} /> */}
            </ColumnContainer>
          </div>
        </StyledContainer>
      </ConditionalRender>
    </SettingsContainer>
  )
}
